import {
  SET_HEADER1_MENU_OPTIONS,
  SET_CUSTOMER_SUPPORT_DATA,
  SET_CHECK_NEW_NOTIFICATION,
} from './constants';

import { SET_LOGGEDIN_USER_INFO } from 'redux/actions/global-actions';

const initialState = {
  loggedInUserInfo: {},
  customerSupportData: {
    country: [],
  },
  header1MenuOptions: {},
  checkNewNotificationExist: {},
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGEDIN_USER_INFO:
      return { ...state, loggedInUserInfo: action.payload };
    case SET_HEADER1_MENU_OPTIONS:
      return { ...state, header1MenuOptions: action.payload };
    case SET_CUSTOMER_SUPPORT_DATA:
      return { ...state, customerSupportData: action.payload };
    case SET_CHECK_NEW_NOTIFICATION:
      return { ...state, checkNewNotificationExist: action.payload };
    default:
      return state;
  }
};

export default layoutReducer;
