import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Box, Typography } from '@mui/material';

import InformationIcon from '../../../../assets/icons/information-icon';
import { accountRequestDataLabelsMapping } from '../constants';
import { addNewRetailerStyles as Styles } from '../styles';

const areAllValuesEmpty = (obj) => {
  return Object.values(obj).every((value) => !value);
};

const DetailsSection = ({ title, details }) => {
  if (areAllValuesEmpty(details)) return null;

  return (
    <Box sx={Styles.accountRequestInfo.section}>
      <Typography sx={{ fontWeight: 900 }}>{title}</Typography>
      {Object.entries(details).map(([key, value]) => {
        if (!value) return null;
        return (
          <Typography
            key={`${title.toLowerCase().replace(' ', '_')}_${key}`}
            sx={Styles.accountRequestInfo.sectionItems}
          >
            <Typography
              key={`company_details_${key}`}
              sx={Styles.accountRequestInfo.sectionItems}
            >
              {key === 'company_name'
                ? value
                : `${accountRequestDataLabelsMapping[key] ?? key}: ${value}`}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};

DetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};

export default function AccountRequestInfo({ data }) {
  const hasBillingAddress = !areAllValuesEmpty(data?.billing_address);
  const hasShippingAddress = !areAllValuesEmpty(data?.shipping_address);
  const showBorder = hasBillingAddress || hasShippingAddress; // Show border if any address is available

  return (
    <Box sx={Styles.accountRequestInfo.container}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={Styles.accountRequestInfo.header}
      >
        <InformationIcon
          fill='#004179'
          sx={{ width: '15px', height: '15px' }}
        />
        <Typography
          variant='body2'
          color='#004179'
          sx={{ fontSize: '14px', fontWeight: 900 }}
        >
          Account request submitted by:
        </Typography>
      </Stack>
      <Stack spacing={1} sx={{ padding: '16px' }}>
        <Stack direction='row' sx={{ flexWrap: 'wrap', gap: 2 }}>
          <DetailsSection title='Buyer Details' details={data?.buyer_details} />
          <DetailsSection
            title='Company Details'
            details={data?.company_details}
          />
        </Stack>
        {showBorder && <Box sx={{ borderBottom: '1px dashed #B7DDFF' }} />}
        <Stack direction='row' sx={{ flexWrap: 'wrap', gap: 2 }}>
          <DetailsSection
            title='Billing Address'
            details={data?.billing_address}
          />
          <DetailsSection
            title='Shipping Address'
            details={data?.shipping_address}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

AccountRequestInfo.propTypes = {
  data: PropTypes.object,
};

AccountRequestInfo.defaultProps = {
  data: {},
};
